.cstm-body{
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 2%;
}

.cstm-header-link{
    color: #cc2755 !important;
    text-decoration:none;
}

.cstm-header-link:hover{
    text-decoration:underline;
}

.cstm-btn-load{
    outline: none;
    border-color: #cc2856 !important;
    background-color: #cc2856 !important;
    opacity:90%;
    box-shadow: none;
    width:120px;
}

.cstm-btn-load:hover{
    opacity:100%;
}