#pad {
    max-width: 100%;
    padding-top: 3.5%;
    padding-bottom: 2%;
}

.cstm-btn{
    opacity:90%;
}

.cstm-btn:hover{
    opacity:100%;
}

.footer{
    z-index: -1;
}