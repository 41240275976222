/* * { outline: 1px solid red; } */

.cstm-btn-search-modal{
    background-color: #cc2755 !important;
    outline:none !important;
    border: none !important;
}

.cstm-modal-sugg {
    outline: none;
    box-shadow: none;
    border: none !important;
}

.cstm-modal-input:focus {
    outline: none;
    border-color: #cc2856 !important;
    box-shadow: none;
}